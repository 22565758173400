import React from 'react';

import {ErrorMessageProps} from './types';
import {Wrapper, ErrorList} from './styles';

const ErrorMessage: React.FC<ErrorMessageProps> = ({errors, name}) => (
  <Wrapper>
    <strong>{`${name}:`}</strong>
    <ErrorList>
      {errors.map((error: string) => (
        <li key={error}>{error}</li>
      ))}
    </ErrorList>
  </Wrapper>
);

export default ErrorMessage;
