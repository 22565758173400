import Button from 'components/Button';
import Overlay from 'components/Overlay';

import {AlertWrapper, Alert, AlertText} from './styles';
import {AlertProps} from './types';

const SoundAlert: React.FC<AlertProps> = ({handleClick}) => (
  <AlertWrapper>
    <Overlay onClick={handleClick} />
    <Alert>
      <AlertText>Pozwól odtwarzać dźwięk</AlertText>
      <Button onClick={handleClick}>Zamknij</Button>
    </Alert>
  </AlertWrapper>
);

export default SoundAlert;
