import {styled} from 'styles';

import {ValueProps} from './types';

export const CountWrapper = styled.div`
  white-space: nowrap;
  text-align: center;
`;

export const Value = styled.span<ValueProps>`
  ${({theme, inKitchen}) => `
    color: ${theme.palette.text[inKitchen ? 'default' : 'main']};
    font-family: ${theme.font.family.fancy};
    font-size: ${theme.font.size.xl};
    margin-right: 0.1em;
    font-weight: bold;
  `}
`;
