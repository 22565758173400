import {HTMLAttributes} from 'react';

import {styled} from '../../styles';
import cross from '../../assets/images/cross.svg';

export const Fieldset = styled.div`
  ${({theme}) => `
    padding: 0 0 ${theme.spacing.base * 2.75}px 0;
    margin: 0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    label {
      font-size: ${theme.font.size.default};
      color: ${theme.palette.text.default};
    }

    input {
      width: 85%;
      display: block;
      color: ${theme.palette.text.default};
      background-color: ${theme.palette.background.secondary};
      border: none;
      border-radius: 4px;
      font-size: ${theme.font.size.md};
      padding:
        ${theme.spacing.base * 1.75}px
        ${theme.spacing.base * 2.75}px
        ${theme.spacing.base * 1.75}px
        ${theme.spacing.base * 2}px;
      box-sizing: border-box;
      margin: 0;
      line-height: 1.5;
      box-shadow: ${theme.shadows[0]};
      transition: box-shadow ${theme.transitions[0]};
    }

    input:active,
    input:focus {
      background-color: ${theme.palette.background.secondary};
      box-shadow: ${theme.shadows[1]};
      outline: none;
    }

    input::placeholder {
      color: ${theme.palette.text.default};
      font-style: oblique;
      opacity: 0.75;
    }
  `}
`;

export const Clear = styled.button<
  HTMLAttributes<HTMLButtonElement> & {visible: boolean}
>`
  ${({theme, visible}) => `
    background-clip: content-box;
    padding: 10px;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -30px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    background-color: ${theme.palette.primary.transparent};
    transform: scale(${visible ? 1 : 0});
    background-image: url(${cross});
    background-position: center;
    background-repeat: no-repeat;
    transition: transform ${theme.transitions[1]}, background ${
    theme.transitions[1]
  };
    
    &:hover {
      background-color: ${theme.palette.primary.main};
      transition: transform ${theme.transitions[2]}, background ${
    theme.transitions[2]
  };
    }
    
    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  `}
`;

export const Submit = styled.button`
  ${({theme}) => `
    color: ${theme.palette.text.light};
    background-color: ${theme.palette.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border: none;
    border-radius: 4px;
    min-width: ${theme.spacing.base * 12.5}px;
    padding: ${theme.spacing.base * 2}px;
    cursor: pointer;
    transition: transform ${theme.transitions[1]};
    
    &:active {
      transform: scale(0.95);
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  `}
`;
