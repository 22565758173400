import {ProductWrapper} from './styles';
import {DishProductProps} from './types';

const DishProduct: React.FC<DishProductProps> = ({
  name,
  description,
  variant,
  inKitchen,
  ...rest
}) => (
  <ProductWrapper inKitchen={inKitchen} {...rest}>
    <strong>{name}</strong>
    <strong>{variant}</strong>
    {description && <div>{description}</div>}
  </ProductWrapper>
);

export default DishProduct;
