import {makeAutoObservable, runInAction} from 'mobx';
import {toast} from 'react-toastify';

import api from 'api';
import {TOKEN_INVALID_MESSAGE} from 'consts';
import {
  LoginDetails,
  TokenSuccessResponse,
  TokenErrorResponse,
  RefreshToken,
} from 'api/types';
import RootStore from 'stores/Root';

import {
  ACCESS_TOKEN,
  LOGOUT_ERROR,
  REFRESH_TOKEN,
  SESSION_EXPIRED,
} from './consts';

export default class SessionStore {
  store: RootStore;

  isLoading: boolean;

  loggedIn: boolean;

  fieldErrors: {[key: string]: string[]};

  constructor(rootStore: RootStore) {
    this.store = rootStore;
    this.isLoading = false;
    this.fieldErrors = {};
    this.loggedIn = !!SessionStore.accessToken || !!SessionStore.refreshToken;

    makeAutoObservable(this, {}, {autoBind: true});
  }

  setLoading(value: boolean): void {
    this.isLoading = value;
  }

  reset(): void {
    this.isLoading = false;
    this.loggedIn = false;
    this.fieldErrors = {};
  }

  async logIn(credentials: LoginDetails): Promise<void> {
    try {
      this.setLoading(true);
      const {data} = await api.login(credentials);
      if (data?.tokenPair) {
        if ('fieldErrors' in data.tokenPair) {
          this.handleTokenPairErrors(data.tokenPair);
        } else {
          SessionStore.updateTokenPair(data.tokenPair);
          runInAction(() => {
            this.store.pusher.updateConfig();
            this.loggedIn = true;
          });
        }
      }
    } catch (error) {
      if (error.message) {
        toast.error(error.message);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async refreshToken(): Promise<void> {
    if (!SessionStore.refreshToken) {
      this.handleInvalidToken();
      return;
    }
    try {
      this.setLoading(true);
      const {data} = await api.updateToken(SessionStore.refreshToken);
      this.handleRefreshTokenResponse(data);
    } catch (error) {
      if (error?.message === TOKEN_INVALID_MESSAGE) {
        this.handleInvalidToken();
      } else toast.error(error);
    } finally {
      this.setLoading(false);
    }
  }

  handleInvalidToken(): void {
    toast.error(SESSION_EXPIRED);
    this.logOut();
  }

  handleRefreshTokenResponse(response: RefreshToken | null | undefined): void {
    if (!response) return;
    if ('fieldErrors' in response.tokenRefresh) {
      this.handleInvalidToken();
    } else {
      SessionStore.updateTokenPair(response.tokenRefresh);
      this.loggedIn = true;
    }
  }

  handleTokenPairErrors({fieldErrors}: TokenErrorResponse): void {
    if (!fieldErrors) return;
    this.fieldErrors = Object.assign(
      {},
      ...fieldErrors.map(({name, errors}) => ({
        [name]: errors,
      })),
    );
  }

  private resetAuthData(): void {
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    this.store.orders.reset();
    this.reset();
  }

  async logOut(): Promise<void> {
    if (!SessionStore.refreshToken) {
      this.resetAuthData();
      return;
    }
    try {
      const {data} = await api.logout(SessionStore.refreshToken);
      if (data?.logout.success) {
        this.resetAuthData();
        return;
      }
      toast.error(LOGOUT_ERROR);
    } catch (error) {
      if (error?.message) {
        toast.error(error?.message);
      }
    }
  }

  static get accessToken(): string | null {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  static get refreshToken(): string | null {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  static updateTokenPair({access, refresh}: TokenSuccessResponse): void {
    localStorage.setItem(ACCESS_TOKEN, access);
    localStorage.setItem(REFRESH_TOKEN, refresh);
  }
}
