export const GET_BOX_CONFIG_FAIL =
  'Nie udało się pobrać konfiguracji Krakomatu';

export const PREPARE_DISH_FAIL = 'Nie udało się zmienić statusu potrawy.';
export const FETCH_ORDERS_FAIL = 'Wystąpił problem przy pobieraniu zamówień.';

export const FETCH_ORDERS_INVALID_TOKEN_ERROR = 'Proszę zalogować się ponownie';
export const FETCH_ORDERS_RESPONSE_ERROR = `${FETCH_ORDERS_FAIL} Spróbuj odświeżyć kartę lub zalogować się ponownie.`;

export const GET_RESTAURANT_FAIL = 'Nie udało się pobrać listy restauracji.';

export const MISSING_RESTAURANT_ID =
  'Brakująca informacja o obecnie wybranej restauracji.';

export const FETCH_ORDER_FAIL_MISSING_RESTAURANT_ID = `${FETCH_ORDERS_FAIL} ${MISSING_RESTAURANT_ID}`;
export const GET_BOX_CONFIG_FAIL_MISSING_RESTAURANT_ID = `${GET_BOX_CONFIG_FAIL} ${MISSING_RESTAURANT_ID}`;

/**
 * Local storage keys
 */
export const RESTAURANT_ID = 'restaurant_id';
