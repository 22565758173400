import {OrdersCountProps} from './types';
import {Wrapper, Count} from './styles';

const OrdersCount: React.FC<OrdersCountProps> = ({label, count}) => (
  <Wrapper>
    <span>{label}</span>
    {!!count && <Count>{`x ${count}`}</Count>}
  </Wrapper>
);

export default OrdersCount;
