export enum DropTarget {
  KITCHEN = 'kitchen',
  TRAY = 'tray',
}

export type DragDish = {
  id: string;
  orderId: string;
  isSingle: boolean;
  allowedDropTarget?: DropTarget;
};

export enum TransferTarget {
  newOrders = 'newOrders',
  inPreparation = 'inPreparation',
  readyToPickup = 'readyToPickup',
}

export type PendingAction = () => Promise<void>;
