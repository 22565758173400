import {styled} from 'styles';

import {TimeProps, NumberProps, WrapperProps} from './types';

export const Wrapper = styled.div<WrapperProps>`
  ${({theme, ready, inKitchen}) => `
    display: flex;
    border: 2px solid ${theme.palette.primary.main};
    ${ready ? `border-color: ${theme.palette.grey[600]}` : ''};
    ${inKitchen ? `border-color: ${theme.palette.highlight.main}` : ''};
    border-radius: 4px;
    overflow: hidden;
    color: ${theme.palette.text.default};
    user-select: none;

    & > *:nth-of-type(1) {
      width: 30%;
    }

    & > *:nth-of-type(2) {
      width: 70%;
    }

    & + & {
      margin-top: -2px;
    }

    ${
      ready
        ? `
      filter: grayscale(100%) opacity(0.25);
    `
        : ''
    }
  `}
`;

export const Summary = styled.div`
  ${({theme}) => `
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    background: ${theme.palette.primary.light};
    padding: ${theme.spacing.base}px;
    border-right: 2px solid ${theme.palette.grey[200]};
    
    & > div {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
    }
  `}
`;

export const Time = styled.span<TimeProps>`
  ${({theme, size, inKitchen}) => `
    color: ${theme.palette.text[inKitchen ? 'default' : 'main']};
    font-family: ${theme.font.family.fancy};
    font-size: ${theme.font.size[size]};
    margin-left: auto;
    font-weight: bold;
    flex-basis: 40%;
  `}
`;

export const Number = styled.span<NumberProps>`
  ${({theme, inKitchen}) => `
    color: ${theme.palette.text[inKitchen ? 'default' : 'main']};
    font-family: ${theme.font.family.fancy};
    font-size: ${theme.font.size.l};
    margin-left: 0.2em;
    margin-right: auto;
    font-weight: bold;
    text-align: center;
  `}
`;

export const Notes = styled.div`
  margin-top: 0.75em;
  font-style: oblique;
`;
