export const mapNameToLabel = (name: string): string => {
  switch (name) {
    case 'username':
      return 'login';
    case 'password':
      return 'hasło';
    default:
      return name;
  }
};
