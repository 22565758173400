import styled from '@emotion/styled/macro';
import {CreateStyled} from '@emotion/styled';

export const theme = {
  palette: {
    primary: {
      main: '#e50067',
      light: '#ffffff',
      transparent: 'rgba(229, 0, 103, 0.5)',
    },
    secondary: {
      main: '#bac914',
      light: '#f27fb2',
    },
    highlight: {
      main: '#329753',
      light: '#aed7ae',
    },
    grey: {
      200: '#e3e3e3',
      300: '#d4d4d4',
      600: '#7d7d7d',
    },
    text: {
      main: '#e50067',
      default: '#7d7d7d',
      light: '#ffffff',
      dark: '#707070',
    },
    background: {
      main: '#ffffff',
      secondary: '#f7f8fd',
      app: '#f7f8fd',
      hover: '#f27fb2',
    },
  },
  font: {
    family: {
      default: 'Poppins, sans-serif',
      fancy: 'Merriweather, serif',
    },
    size: {
      default: '14px',
      sm: '12px',
      md: '15px',
      l: '22px',
      xl: '32px',
      xxl: '56px',
    },
  },
  spacing: {
    base: 8,
  },
  shadows: [
    '0 0 0 1px #d4d4d4',
    '0 0 0 2px #e50067',
    '0 1px 2px rgba(0, 0, 0, 0.3)',
    '2px 3px 8px 0 rgba(229, 0, 103, 0.5)',
    '0 0 0 2px',
  ],
  gradients: [
    'linear-gradient(to bottom,transparent,transparent calc(100% - 20px),rgba(140, 139, 139, 0.1))',
  ],
  transitions: [
    '300ms ease-out',
    '150ms cubic-bezier(0.2, 0.2, 0.5, 2)',
    '100ms cubic-bezier(1, 1, 0.75, 1)',
  ],
};

export default styled as CreateStyled<typeof theme>;
