import {styled} from '../../styles';

import {ClockProps} from './types';

export const Wrapper = styled.div<ClockProps>`
  ${({theme, column}) => `
    color: ${theme.palette.text.default};
    line-height: 1.4;
    ${
      column &&
      `
      height: 0;
      margin-bottom: ${theme.spacing.base * 2.5}px;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
    `
    }
  `}
`;

export const Time = styled.span<ClockProps>`
  ${({theme, column}) => `
    color: ${theme.palette.primary.main};
    font-family: ${theme.font.family.fancy};
    font-weight: 700;
    line-height: 1.4;
    margin-left: ${theme.spacing.base * 2.5}px;
    margin-right: ${theme.spacing.base * 2.5}px;

    ${
      column
        ? `
      font-size: ${theme.font.size.xxl};
    `
        : `
      font-size: ${theme.font.size.xl};
    `
    }
  `}
`;
