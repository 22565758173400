import {gql} from '@apollo/client';

const TOKENS_FRAGMENT = gql`
  fragment Tokens on AuthTokens {
    access
    refresh
  }
`;

export const LOGIN_ERRORS_FRAGMENT = gql`
  fragment LoginErrors on GraphqlResult {
    fieldErrors {
      name
      errors
    }
    error {
      name
      errors
    }
  }
`;

export const LOGIN = gql`
  mutation Auth($username: String!, $password: String!) {
    tokenPair(username: $username, password: $password) {
      ...Tokens
      ...LoginErrors
    }
  }
  ${TOKENS_FRAGMENT}
  ${LOGIN_ERRORS_FRAGMENT}
`;

export const REFRESH = gql`
  mutation Refresh($refreshToken: String!) {
    tokenRefresh(refreshToken: $refreshToken) {
      ...Tokens
      ...LoginErrors
    }
  }
  ${TOKENS_FRAGMENT}
  ${LOGIN_ERRORS_FRAGMENT}
`;

export const LOGOUT = gql`
  mutation Logout($refreshToken: String!) {
    logout(refreshToken: $refreshToken) {
      success
    }
  }
`;

export const BOXCONFIG = gql`
  query BoxConfig($restaurantId: Int!) {
    boxConfig: config(restaurantId: $restaurantId) {
      enabled: boxesEnabled
      cancelActionTime
    }
  }
`;

export const USER_RESTAURANTS = gql`
  query UserRestaurants {
    userRestaurants {
      id
      name
    }
  }
`;

export const ORDERS = gql`
  query Orders($status: Int!, $restaurantId: Int!) {
    basketItemList(status: $status, restaurantId: $restaurantId) {
      itemGroups {
        id
        deliveryType
        requestedDate
        orderNumber
        notes
        items {
          id
          product {
            id
            name
            description
          }
          variant {
            id
            name
            price
          }
          addons {
            id
            addonId
            name
            price
            addonType
          }
          price
          count
        }
      }
    }
  }
`;

export const START_PREPARE_DISH = gql`
  mutation StartPrepareDish($id: String!) {
    result: startPrepareMeal(basketItemId: $id) {
      success
    }
  }
`;

export const COMPLETE_PREPARE_DISH = gql`
  mutation PrepareDish($id: String!) {
    result: prepareMeal(basketItemId: $id) {
      success
    }
  }
`;
