import React, {useEffect, useRef} from 'react';
import Loader from 'react-spinners/PulseLoader';
import {toast} from 'react-toastify';
import {observer} from 'mobx-react-lite';

import Clock from 'components/Clock';
import OrdersCount from 'components/OrdersCount';
import Overlay from 'components/Overlay';
import CancelAction from 'components/CancelAction';
import SoundAlert from 'containers/Orders/SoundAlert';
import {theme} from 'styles';
import {useDelay, useStore} from 'utils';

import Tray from './Tray';
import NewOrders from './NewOrders';
import Kitchen from './Kitchen';
import RestaurantPicker from './RestaurantPicker';

import {
  Wrapper,
  Header,
  OrdersList,
  Logout,
  HeaderRight,
  BoxesStatus,
  BoxesInfo,
} from './styles';

const Orders: React.FC = () => {
  const {sessionStore, ordersStore, pusherStore, rootStore} = useStore();
  const isLoading = useDelay(ordersStore.isLoading, 300);
  const timeoutRef = useRef(1);

  const handleClick = (): void => {
    pusherStore.setIsSoundDisabled(false);
  };

  useEffect(() => {
    rootStore.initializeStores();
  }, [rootStore]);

  useEffect(() => {
    if (ordersStore.error) {
      toast.error(ordersStore.error, {
        onClose: ordersStore.clearError,
      });
    }
  }, [ordersStore]);

  useEffect(() => {
    ordersStore.setTimeoutRef(timeoutRef.current);
  }, [ordersStore, timeoutRef]);

  return (
    <Wrapper>
      <Header>
        <Clock />
        <OrdersCount
          label="nowe zamówienia "
          count={ordersStore.newOrders.length}
        />
        <RestaurantPicker />
        <HeaderRight>
          <BoxesInfo>
            {'Boxy: '}
            <BoxesStatus>{ordersStore.boxesEnabled ? 'ON' : 'OFF'}</BoxesStatus>
          </BoxesInfo>
          <Logout role="button" onClick={sessionStore.logOut}>
            wyloguj
          </Logout>
        </HeaderRight>
      </Header>
      <OrdersList disabled={isLoading}>
        <NewOrders />
        <OrdersCount
          label="w realizacji "
          count={ordersStore.inPreparation.length}
        />
        <Kitchen />
        <OrdersCount
          label="do odbioru "
          count={ordersStore.readyToPickup.length}
        />
        <Tray />
      </OrdersList>
      {isLoading && !ordersStore.isDragging && (
        <Overlay>
          <Loader size={8} color={theme.palette.primary.main} />
        </Overlay>
      )}
      <CancelAction visible={!!ordersStore.pendingAction} />
      {pusherStore.isSoundDisabled && <SoundAlert handleClick={handleClick} />}
    </Wrapper>
  );
};

export default observer(Orders);
