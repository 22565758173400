export const TRAY_TIMEOUT = 15000;

export const TOAST_AUTOCLOSE = 5000;

export const TOKEN_INVALID_MESSAGE = 'Token is invalid or expired';

export enum OrdersTypeCode {
  NEW = 15,
  IN_PREPARATION = 20,
  PREPARED = 30,
  DELIVERED = 40,
}

export enum DeliveryType {
  PREORDER = 'w lokalu',
  TAKEAWAY = 'na wynos',
}
