import {FetchResult} from '@apollo/client/link/core/types';

import graphqlClient from './graphql';

import {
  LOGIN,
  ORDERS,
  REFRESH,
  BOXCONFIG,
  START_PREPARE_DISH,
  COMPLETE_PREPARE_DISH,
  USER_RESTAURANTS,
  LOGOUT,
} from './queries';
import {
  LoginDetails,
  LogoutResult,
  OrdersData,
  OrderQueryProps,
  ObtainToken,
  RefreshToken,
  PrepareDishData,
  BoxConfigData,
  UserRestaurantData,
  BoxConfigQueryProps,
} from './types';

export default {
  login: (details: LoginDetails): Promise<FetchResult<ObtainToken>> =>
    graphqlClient.mutate<ObtainToken>({
      mutation: LOGIN,
      variables: details,
    }),
  logout: (refreshToken: string): Promise<FetchResult<LogoutResult>> =>
    graphqlClient.mutate<LogoutResult>({
      mutation: LOGOUT,
      variables: {
        refreshToken,
      },
    }),
  getBoxConfig: (
    filters: BoxConfigQueryProps,
  ): Promise<FetchResult<BoxConfigData>> =>
    graphqlClient.query<BoxConfigData>({
      query: BOXCONFIG,
      variables: filters,
    }),
  getUserRestaurants: (): Promise<FetchResult<UserRestaurantData>> =>
    graphqlClient.query<UserRestaurantData>({
      query: USER_RESTAURANTS,
    }),
  fetchOrders: (filters: OrderQueryProps): Promise<FetchResult<OrdersData>> =>
    graphqlClient.query<OrdersData>({
      query: ORDERS,
      variables: filters,
      fetchPolicy: 'network-only',
    }),
  startPrepareDish: (id: string): Promise<FetchResult<PrepareDishData>> =>
    graphqlClient.mutate<PrepareDishData>({
      mutation: START_PREPARE_DISH,
      variables: {
        id,
      },
    }),
  completePrepareDish: (id: string): Promise<FetchResult<PrepareDishData>> =>
    graphqlClient.mutate<PrepareDishData>({
      mutation: COMPLETE_PREPARE_DISH,
      variables: {
        id,
      },
    }),
  updateToken: (refreshToken: string): Promise<FetchResult<RefreshToken>> =>
    graphqlClient.mutate<RefreshToken>({
      mutation: REFRESH,
      variables: {
        refreshToken,
      },
    }),
};
