import {styled} from 'styles';

export const Button = styled.button`
  ${({theme}) => `
    color: ${theme.palette.text.light};
    background-color: ${theme.palette.primary.main};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border: none;
    border-radius: 4px;
    min-width: ${theme.spacing.base * 12.5}px;
    padding: ${theme.spacing.base * 2}px;
    cursor: pointer;
    transition: transform ${theme.transitions[1]};
    
    &:active {
      transform: scale(0.95);
    }

    &[disabled] {
      opacity: 0.5;
      pointer-events: none;
    }
  `}
`;
