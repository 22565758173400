import {forwardRef, useState, FormEvent} from 'react';

import {Fieldset, Clear} from './styles';
import {InputProps} from './types';

const Input: React.ForwardRefExoticComponent<
  InputProps & React.RefAttributes<HTMLInputElement>
> = forwardRef(({name, label, placeholder, ...props}, ref) => {
  const [value, setValue] = useState('');

  const handleInput = (e: FormEvent<HTMLInputElement>): void => {
    e.preventDefault();
    setValue(e.currentTarget.value);
  };

  const handleClear = (e: FormEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setValue('');
  };

  return (
    <Fieldset>
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        name={name}
        value={value}
        onChange={handleInput}
        placeholder={placeholder}
        ref={ref}
        {...props}
      />
      <Clear
        onClick={handleClear}
        visible={value.length > 0}
        tabIndex={-1}
        type="reset"
      />
    </Fieldset>
  );
});

export default Input;
