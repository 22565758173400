import {styled} from 'styles';
import Button from 'components/Button';
import arrow from 'assets/images/undo-arrow.svg';

import {CancelBarProps} from './types';

export const CancelBar = styled.div<CancelBarProps>`
  box-sizing: border-box;
  width: 90%;
  width: calc(100% - ${({theme}) => theme.spacing.base * 5}px);
  margin-top: auto;
  margin-bottom: ${({theme, visible}) =>
    theme.spacing.base * (visible ? 3 : -7)}px;
  background: ${({theme}) => theme.palette.background.main};
  padding: ${({theme}) =>
    `${theme.spacing.base * 1.5}px ${theme.spacing.base * 2.5}px`};
  border-radius: ${({theme}) => theme.spacing.base * 0.5}px;
  box-shadow: ${({theme}) => `
    ${theme.shadows[1]}, ${theme.shadows[2]}, ${theme.shadows[3]}
  `};
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.palette.text.main};
  font-family: ${({theme}) => theme.font.family.default};
  font-size: ${({theme}) => theme.font.size.md};

  transition: margin-bottom ${({theme}) => theme.transitions[0]};

  @media (min-width: 600px) {
    width: 500px;
  }
`;

export const CancelButton = styled(Button)`
  padding-left: ${({theme}) => theme.spacing.base * 5}px;
  background-image: url(${arrow});
  background-repeat: no-repeat;
  background-position: 10px 50%;
  background-size: 20px;
  margin-left: 0;
  margin-right: ${({theme}) => -theme.spacing.base}px;
`;

export const AcceptButton = styled(Button)`
  color: ${({theme}) => theme.palette.primary.main};
  box-shadow: inset 0 0 0 2px ${({theme}) => theme.palette.primary.main};
  background-color: ${({theme}) => theme.palette.background.main};
  margin-right: ${({theme}) => theme.spacing.base}px;
  margin-left: auto;
`;
