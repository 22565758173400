import {OrdersTypeCode} from 'consts';
import OrdersStore from 'stores/Orders';
import PusherStore from 'stores/Pusher';
import SessionStore from 'stores/Session';

import {Stores, IRootStore} from './types';

export default class RootStore implements IRootStore {
  session: SessionStore;

  orders: OrdersStore;

  pusher: PusherStore;

  constructor() {
    this.session = new SessionStore(this);
    this.orders = new OrdersStore(this);
    this.pusher = new PusherStore(this);
  }

  async initializeStores(): Promise<void> {
    await this.stores.ordersStore.getUserRestaurants();
    this.stores.ordersStore.getBoxConfig();
    this.stores.pusherStore.subscribeToOrders();
    this.stores.pusherStore.bindConfigChange();
    await this.stores.ordersStore.fetchOrders(OrdersTypeCode.NEW);
    await this.stores.ordersStore.fetchOrders(OrdersTypeCode.IN_PREPARATION);
  }

  get stores(): Stores {
    return {
      rootStore: this,
      sessionStore: this.session,
      ordersStore: this.orders,
      pusherStore: this.pusher,
    };
  }
}
