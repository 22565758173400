import {styled} from 'styles';

import {OverlayProps} from './types';

export const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: ${({visible = true}) => (visible ? 0 : '100%')};
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity ${({theme}) => theme.transitions[0]};
  opacity: ${({visible = true}) => (visible ? 1 : 0)};
  display: flex;
  align-items: center;
  justify-content: center;
`;
