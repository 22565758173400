import {FormatTimeType} from './types';

const formatTime: FormatTimeType = (timestamp) => ({
  date: new Intl.DateTimeFormat('pl-PL', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
  }).format(timestamp),
  time: new Intl.DateTimeFormat('pl-PL', {
    hour: 'numeric',
    minute: 'numeric',
  }).format(timestamp),
});

export default formatTime;
