import {useEffect, useState} from 'react';

import {formatTime} from 'utils';

import {Wrapper, Time} from './styles';
import {ClockProps} from './types';

const Clock: React.FC<ClockProps> = ({column}) => {
  const [currentDate, setCurrentDate] = useState(formatTime(Date.now()));

  useEffect(() => {
    const clock = setInterval(() => {
      setCurrentDate(formatTime(Date.now()));
    }, 1000);

    return () => clearInterval(clock);
  }, []);

  return (
    <Wrapper column={column}>
      {currentDate.date}
      <Time column={column}>{currentDate.time}</Time>
    </Wrapper>
  );
};

export default Clock;
