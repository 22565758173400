import {observer} from 'mobx-react-lite';

import {useStore} from 'utils';

const RestaurantPicker: React.FC = () => {
  const {ordersStore} = useStore();
  const {
    currentRestaurant,
    availableRestaurants,
    changeRestaurant,
  } = ordersStore;

  const onChangeRestaurant = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    changeRestaurant(e.target.value);
  };

  if (!currentRestaurant) {
    return null;
  }

  return (
    <select value={currentRestaurant} onChange={onChangeRestaurant}>
      {Object.entries(availableRestaurants).map(([id, name]) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default observer(RestaurantPicker);
