import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Loader from 'react-spinners/PulseLoader';
import {observer} from 'mobx-react-lite';

import Button from 'components/Button';
import Form from 'components/Form';
import Input from 'components/Input';
import ErrorMessage from 'components/ErrorMessage';
import Clock from 'components/Clock';
import {LoginDetails} from 'api/types';
import {useDelay, useStore} from 'utils';

import {mapNameToLabel} from './utils';

export const Login: React.FC = () => {
  const {sessionStore} = useStore();
  const {register, handleSubmit} = useForm<LoginDetails>();

  const isLoading = useDelay(sessionStore.isLoading, 300);

  useEffect(() => {
    Object.entries(sessionStore.fieldErrors).forEach(([key, value]) =>
      toast.error(<ErrorMessage errors={value} name={mapNameToLabel(key)} />),
    );
  }, [sessionStore.fieldErrors]);

  return (
    <>
      <Clock column />
      <Form onSubmit={handleSubmit(sessionStore.logIn)}>
        <Input
          name="username"
          label="login:"
          placeholder="imię@domena.pl"
          ref={register}
        />
        <Input
          name="password"
          type="password"
          label="hasło:"
          placeholder="··········"
          ref={register}
        />
        <Button type="submit" disabled={isLoading}>
          {isLoading ? <Loader size={4} color="#ffffff" /> : 'ok'}
        </Button>
      </Form>
    </>
  );
};

export default observer(Login);
