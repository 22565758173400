import {useContext} from 'react';

import storesContext from 'stores/context';

import {UseStoreHook} from './types';

const useStore: UseStoreHook = () => {
  const stores = useContext(storesContext);

  if (!stores) throw new Error('No stores available within the context');

  return stores;
};

export default useStore;
