import {styled} from 'styles';

import {AddonsProps, ContentProps} from './types';

export const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
`;

export const PadLayer = styled.div`
  ${({theme}) => `
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.primary.main};
  `}
`;

export const Content = styled.div<ContentProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({theme}) => theme.spacing.base}px;
  color: ${({theme}) => theme.palette.text.default};
  background-color: ${({theme, opaque}) =>
    theme.palette[!opaque ? 'primary' : 'background'].main};
  background-image: ${({theme}) => theme.gradients[0]};
  ${({ready}) => (ready ? '' : 'cursor: move;')};

  & > * {
    opacity: ${({opaque}) => (opaque ? 1 : 0)};
  }

  & > *:nth-of-type(1) {
    width: 40%;
  }

  & > *:nth-of-type(2) {
    width: 20%;
  }

  & > *:nth-of-type(3) {
    width: 40%;
  }
`;

export const Addons = styled.ul<AddonsProps>`
  ${({theme, inKitchen}) => `
    margin: 0;
    font-size: ${theme.font.size.md};

    li::marker {
      color: ${theme.palette.text[inKitchen ? 'default' : 'main']};
    }
  `}
`;
