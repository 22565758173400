import bg from 'assets/images/bg.png';
import bgBottom from 'assets/images/bg_bottom.png';
import {styled} from 'styles';

export const Container = styled.div`
  ${({theme}) => `
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-color: ${theme.palette.background.app};
    background-image: url(${bg}), url(${bgBottom});
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: 0 0, 0 100%;
  `}
`;
