import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  Observable,
  from,
} from '@apollo/client';
import {GraphQLError} from 'graphql';
import {onError} from '@apollo/client/link/error';
import {toast} from 'react-toastify';

import api from 'api';
import {TOKEN_INVALID_MESSAGE} from 'consts';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER,
});

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = window.localStorage.getItem('access_token');

  operation.setContext(({headers = {}}) => ({
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : null,
    },
  }));

  return forward(operation);
});

const invalidAccessToken = `string='AccessToken', code='token_not_valid'`;

const isAccessTokenOutdated = (errors: GraphQLError[]): boolean =>
  errors.some((e) => e.message.indexOf(invalidAccessToken) !== -1);

const errorLink = onError(({graphQLErrors, operation, forward}) => {
  if (!graphQLErrors || !isAccessTokenOutdated([...graphQLErrors])) {
    return undefined;
  }

  return new Observable((observer) => {
    api
      .updateToken(window.localStorage.getItem('refresh_token') as string)
      .then((response) => {
        if (
          response?.data?.tokenRefresh &&
          'access' in response.data.tokenRefresh
        ) {
          const {access} = response.data?.tokenRefresh;

          operation.setContext(({headers = {}}) => ({
            headers: {
              ...headers,
              authorization: access ? `Bearer ${access}` : null,
            },
          }));

          window.localStorage.setItem('access_token', access);
        }
      })
      .then(() => {
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };

        // Retry last failed request
        forward(operation).subscribe(subscriber);
      })
      .catch((error) => {
        if (error.message === TOKEN_INVALID_MESSAGE) {
          toast.error(
            'Wystąpił problem przy pobieraniu zamówień. Spróbuj odświeżyć kartę lub zalogować się ponownie.',
            {
              autoClose: false,
            },
          );
        }
        observer.error({error});
      });
  });
});

const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
});

export default client;
