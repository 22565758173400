import {styled} from 'styles';

export const Wrapper = styled.div`
  user-select: none;
`;

export const ErrorList = styled.ul`
  ${({theme}) => `
    margin-top: ${theme.spacing.base * 0.5}px;
    margin-bottom: ${theme.spacing.base * 0.5}px;
  `}
`;
