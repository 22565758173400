import React from 'react';

import {DeliveryType} from 'consts';
import Dish from 'components/Dish';
import {formatTime} from 'utils';
import {TransferTarget} from 'stores/Orders/types';

import {OrderProps} from './types';
import {Wrapper, Summary, Number, Time, Notes} from './styles';

const Order: React.FC<OrderProps> = ({
  order: {orderNumber, deliveryType, requestedDate, notes, items, id},
  ready,
  origin,
  allowedDropTarget,
}) => {
  const inKitchen = origin === TransferTarget.inPreparation;

  return (
    <Wrapper inKitchen={inKitchen} ready={ready}>
      <Summary>
        <div>
          nr
          <Number inKitchen={inKitchen}>{orderNumber}</Number>
          {DeliveryType[deliveryType]}
          <Time inKitchen={inKitchen} size={requestedDate ? 'l' : 'md'}>
            {requestedDate
              ? formatTime(new Date(requestedDate).getTime()).time
              : 'jak najszybciej'}
          </Time>
          {notes && (
            <Notes>
              <span>Uwaga:</span> {notes}
            </Notes>
          )}
        </div>
      </Summary>
      <div>
        {items.map((item) => (
          <Dish
            key={item.id}
            isSingle={items.length === 1}
            orderId={id}
            ready={ready}
            origin={origin}
            allowedDropTarget={allowedDropTarget}
            {...item}
          />
        ))}
      </div>
    </Wrapper>
  );
};

export default Order;
