import {observer} from 'mobx-react-lite';

import Order from 'components/Order';
import {TransferTarget} from 'stores/Orders/types';
import {useStore} from 'utils';

import {TrayPlaceholder} from './styles';

const Tray: React.FC = () => {
  const {ordersStore} = useStore();

  return (
    <TrayPlaceholder empty={!ordersStore.readyToPickup.length}>
      {ordersStore.readyToPickup.map((order) => (
        <Order
          key={order.id}
          order={order}
          ready
          origin={TransferTarget.readyToPickup}
        />
      ))}
    </TrayPlaceholder>
  );
};

export default observer(Tray);
