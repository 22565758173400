import {styled} from 'styles';

import {ArrowDownProps, OrdersListProps, PlaceholderProps} from './types';

export const Wrapper = styled.div`
  ${({
    theme: {
      spacing: {base},
    },
  }) => `
    box-sizing: border-box;
    width: 100%;
    max-width: 1024px;
    padding: ${base * 0.5}px ${base * 2.5}px ${base * 1.5}px;
    margin-bottom: auto;
    position: relative;
    user-select: none;
  `}
`;

export const Header = styled.div`
  ${({theme}) => `
    display: flex;
    align-items: center;
    color: ${theme.palette.text.default};
    font-size: ${theme.font.size.md};

    & > * {
      flex: 0 1 100%;
    }

    & > *:last-of-type {
      text-align: right;
    }
  `}
`;

export const OrdersList = styled.div<OrdersListProps>`
  ${({theme, disabled}) => `
    height: calc(100vh - 80px);
    transition: filter ${theme.transitions[0]}, opacity ${theme.transitions[0]};
    display: flex;
    flex-direction: column;

    ${
      disabled
        ? `
      opacity: 0.25;
      pointer-events: none;
      filter: grayscale(100%) blur(1px);
    `
        : ``
    }
  `}
`;

export const Logout = styled.div`
  ${({theme}) => `
    font-size: ${theme.font.size.md};
    font-family: ${theme.font.family.fancy};
    cursor: pointer;
    font-weight: 900;
    color: ${theme.palette.primary.main};
  `}
`;

export const Placeholder = styled.div<PlaceholderProps>`
  ${({theme, empty}) => `
    min-height: 112px;
    border-radius: 4px;
    background-color: ${theme.palette.background.main};
    flex-shrink: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    & > * {
      flex-shrink: 0;
      transition: opacity ${theme.transitions[1]};
    }

    ${
      empty
        ? `border: 2px dashed ${theme.palette.secondary.light};`
        : `min-height: unset;`
    }
  `}
`;

export const DropTargetKitchen = styled(Placeholder)`
  ${({theme}) => `
    border-color: ${theme.palette.grey[200]};
    box-shadow:
      40px 0 0 20px ${theme.palette.grey[200]},
      -40px 0 0 20px ${theme.palette.grey[200]};
    margin-top: 20px;
    margin-bottom: 20px;
  `}
`;

export const TrayPlaceholder = styled(Placeholder)`
  ${({theme}) => `
    border-color: ${theme.palette.grey[200]};
  `}
`;

export const ArrowDown = styled.div<ArrowDownProps>`
  ${({theme, folded}) => `
    margin-left: auto;
    padding: 10px;
    margin-bottom: auto;
    cursor: pointer;
    ${folded ? '' : 'transform: scaleY(-1);'}
    transition: transform ${theme.transitions[1]};

    &:active {
      transform: scale(0.95);
    }

    &::before {
      content: '';
      position: relative;
      top: -25%;
      width: 0;
      height: 0;
      display: block;
      padding: 5px;
      border-right: 3px solid ${theme.palette.primary.main};
      border-bottom: 3px solid ${theme.palette.primary.main};
      transform: rotate(45deg);
    }
  `}
`;

export const BoxesInfo = styled.span`
  margin-right: 2em;
`;

export const BoxesStatus = styled.span`
  color: ${({theme}) => theme.palette.primary.main};
  font-family: ${({theme}) => theme.font.family.fancy};
  font-size: ${({theme}) => theme.font.size.l};
  font-weight: 700;
  line-height: 1.4;
`;

export const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
