import {styled} from 'styles';

export const Wrapper = styled.div`
  ${({theme}) => `
    color: ${theme.palette.text.default};
    text-align: center;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: auto;
  `}
`;

export const Count = styled.span`
  ${({theme}) => `
    color: ${theme.palette.primary.main};
  `}
`;
