import {observer} from 'mobx-react-lite';
import {SwipeableList} from '@sandstreamdev/react-swipeable-list';

import Order from 'components/Order';
import {DropTarget, TransferTarget} from 'stores/Orders/types';
import {useStore} from 'utils';

import {DropTargetKitchen} from './styles';

const Kitchen: React.FC = () => {
  const {ordersStore} = useStore();

  return (
    <DropTargetKitchen empty={!ordersStore.inPreparation.length}>
      <SwipeableList>
        {ordersStore.inPreparation.map((order) => (
          <Order
            key={order.id}
            order={order}
            origin={TransferTarget.inPreparation}
            allowedDropTarget={DropTarget.TRAY}
          />
        ))}
      </SwipeableList>
    </DropTargetKitchen>
  );
};

export default observer(Kitchen);
