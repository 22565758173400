import Overlay from 'components/Overlay';
import {DropTarget, TransferTarget} from 'stores/Orders/types';
import {useStore} from 'utils';

import {CancelBar, AcceptButton, CancelButton} from './styles';
import {CancelActionProps} from './types';

const CancelAction: React.FC<CancelActionProps> = ({visible}) => {
  const {ordersStore} = useStore();
  const flushPendingAction = (): void => {
    ordersStore.removePendingAction();
    if (ordersStore.dragDish?.allowedDropTarget === DropTarget.KITCHEN) {
      ordersStore.transferDragDish(
        TransferTarget.inPreparation,
        TransferTarget.newOrders,
        true,
      );
    }
    if (ordersStore.dragDish?.allowedDropTarget === DropTarget.TRAY) {
      ordersStore.transferDragDish(
        TransferTarget.readyToPickup,
        TransferTarget.inPreparation,
        true,
      );
    }
  };
  const runPendingAction = (): void => {
    if (ordersStore.pendingAction) ordersStore.pendingAction();
    ordersStore.removePendingAction();
  };

  return (
    <Overlay visible={visible}>
      <CancelBar visible={visible}>
        {ordersStore.dragDish?.allowedDropTarget === DropTarget.KITCHEN
          ? 'Przekazane do przygotowania'
          : 'Przekazane do odbioru'}
        <AcceptButton onClick={runPendingAction}>Potwierdź</AcceptButton>
        <CancelButton onClick={flushPendingAction}>Cofnij</CancelButton>
      </CancelBar>
    </Overlay>
  );
};

export default CancelAction;
