import {styled} from 'styles';

import {ProductWrapperProps} from './types';

export const ProductWrapper = styled.div<ProductWrapperProps>`
  ${({theme, inKitchen}) => `
    text-align: center;
    font-size: ${theme.font.size.md};

    strong {
      display: block;
      color: ${theme.palette.text[inKitchen ? 'default' : 'main']};
      font-family: ${theme.font.family.fancy};
      font-size: ${theme.font.size.md};
    }
  `}
`;
