import {StrictMode} from 'react';
import ReactDOM from 'react-dom';
import Pusher from 'pusher-js';
import {ThemeProvider} from 'emotion-theming';
import '@openfonts/poppins_latin-ext';
import '@openfonts/merriweather_latin-ext';
import 'react-toastify/dist/ReactToastify.css';

import rootStore from 'stores';
import StoresContext from 'stores/context';

import {theme} from 'styles';

import App from './App';
import './index.css';
import './styles/toasts.css';

if (process.env.NODE_ENV === 'development') {
  Pusher.logToConsole = true;
}

ReactDOM.render(
  <StrictMode>
    <StoresContext.Provider value={rootStore.stores}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StoresContext.Provider>
  </StrictMode>,
  document.getElementById('root'),
);
