import {useState} from 'react';
import {observer} from 'mobx-react-lite';

import Order from 'components/Order';
import {DropTarget, TransferTarget} from 'stores/Orders/types';
import {useStore} from 'utils';

import {Placeholder, ArrowDown} from './styles';

const NewOrders: React.FC = () => {
  const {ordersStore} = useStore();
  const [folded, setFolded] = useState(true);

  const handleUnfold = (): void => setFolded((foldState) => !foldState);

  const orders = ordersStore.newOrders.slice(0, folded ? 2 : undefined);

  return (
    <>
      <Placeholder empty={!ordersStore.newOrders.length}>
        {orders.map((order) => (
          <Order
            key={order.id}
            order={order}
            origin={TransferTarget.newOrders}
            allowedDropTarget={DropTarget.KITCHEN}
          />
        ))}
      </Placeholder>
      {ordersStore.newOrders.length > 2 && (
        <ArrowDown role="button" onClick={handleUnfold} folded={folded} />
      )}
    </>
  );
};

export default observer(NewOrders);
