import {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {
  SwipeableListItem,
  ActionAnimations,
  ISwipeActionProps,
} from '@sandstreamdev/react-swipeable-list';

import Count from 'components/DishCount';
import Product from 'components/DishProduct';
import {useStore} from 'utils';
import {DropTarget, TransferTarget} from 'stores/Orders/types';

import {Addons, Content, PadLayer, Wrapper} from './styles';
import {DishProps} from './types';

const Dish: React.FC<DishProps> = ({
  product,
  variant,
  count,
  addons,
  id,
  orderId,
  isSingle,
  ready,
  origin,
  allowedDropTarget,
}) => {
  const {ordersStore} = useStore();
  const [opaque, setOpaque] = useState(true);
  const inKitchen = origin === TransferTarget.inPreparation;
  const pendingAction = (callback: () => void) => () => {
    ordersStore.setPendingAction(callback);

    if (allowedDropTarget === DropTarget.KITCHEN) {
      ordersStore.transferDragDish(
        TransferTarget.newOrders,
        TransferTarget.inPreparation,
      );
    }

    if (allowedDropTarget === DropTarget.TRAY) {
      ordersStore.transferDragDish(
        TransferTarget.inPreparation,
        TransferTarget.readyToPickup,
      );
    }

    setOpaque(false);

    if (ordersStore.timeoutRef) {
      ordersStore.timeoutRef = window.setTimeout((): void => {
        if (ordersStore.pendingAction) {
          ordersStore.pendingAction().then(ordersStore.removePendingAction);
        }
      }, ordersStore.cancelActionTimeout * 1000);

      return () => clearTimeout(ordersStore.timeoutRef);
    }

    return null;
  };

  const swipe: ISwipeActionProps = {
    content: <PadLayer />,
    actionAnimation: ActionAnimations.RETURN,
    action: inKitchen
      ? pendingAction(ordersStore.completePrepareDish)
      : pendingAction(ordersStore.startPrepareDish),
  };

  const transferDisabled =
    ready ||
    (inKitchen && ordersStore.boxesEnabled) ||
    (inKitchen && !ordersStore.isOrderComplete(orderId));

  const handleTouch = (): void => {
    ordersStore.setDragDish({id, orderId, isSingle, allowedDropTarget});
  };

  return (
    <Wrapper>
      <SwipeableListItem
        blockSwipe={transferDisabled}
        swipeRight={swipe}
        swipeLeft={swipe}>
        <Content
          ready={ready}
          onTouchStart={handleTouch}
          onMouseDown={handleTouch}
          opaque={opaque}>
          <Product
            inKitchen={inKitchen}
            name={product.name}
            variant={variant.name}
            description={product.description}
          />
          <Count inKitchen={inKitchen}>{count}</Count>
          <Addons inKitchen={inKitchen}>
            {addons.map((addon) => (
              <li key={`${id}-${product.id}-${addon.id}`}>{addon.name}</li>
            ))}
          </Addons>
        </Content>
      </SwipeableListItem>
    </Wrapper>
  );
};

export default observer(Dish);
