import {styled} from '../../styles';

export const Form = styled.form`
  ${({theme}) => `
    box-sizing: border-box;
    background-color: ${theme.palette.background.main};
    padding: ${theme.spacing.base * 2.5}px;
    border-radius: 4px;
    box-shadow: ${theme.shadows[1]}, ${theme.shadows[2]};
    width: 90%;
    width: calc(100% - ${theme.spacing.base * 6}px);
    max-width: 520px;
  `}
`;
