import {useEffect, useState} from 'react';

import {UseDelayHook} from './types';

const useDelay: UseDelayHook = (value, delay) => {
  const [state, setState] = useState(false);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (value) {
      setState(true);
    } else {
      timeoutId = setTimeout(() => setState(false), delay);
    }

    return () => clearTimeout(timeoutId);
  }, [value, delay]);

  return state;
};

export default useDelay;
