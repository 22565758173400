import {toast} from 'react-toastify';
import {makeAutoObservable} from 'mobx';
import Pusher, {Channel} from 'pusher-js';

import {OrdersTypeCode} from 'consts';
import RootStore from 'stores/Root';
import SessionStore from 'stores/Session';
import {bell} from 'utils';

import {
  ORDERS_CHANNEL,
  PUSHER_ERROR,
  PUSHER_NEW_ORDER,
  PUSHER_ORDER_STATUS_CHANGE,
  PUSHER_SUCCESS,
  SUBSCRIPTION_ERROR,
  CONFIGURATION_CHANGE,
} from './consts';
import {NewOrderData, StatusChangedData} from './types';

export default class PusherStore {
  store: RootStore;

  pusher: Pusher;

  channel: Channel | null;

  isSoundDisabled = false;

  constructor(rootStore: RootStore) {
    this.store = rootStore;
    this.pusher = PusherStore.createPusher();
    this.channel = null;
    this.isSoundDisabled = false;

    makeAutoObservable(this, {}, {autoBind: true});
  }

  static createPusher(): Pusher {
    return new Pusher(process.env.REACT_APP_PUSHER_KEY as string, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT,
      auth: {
        params: {
          authorization: `Bearer ${SessionStore.accessToken}`,
        },
      },
    });
  }

  updateConfig(): void {
    this.pusher.config.auth = {
      ...this.pusher.config.auth,
      params: {
        ...this.pusher.config.auth?.params,
        authorization: `Bearer ${SessionStore.accessToken}`,
      },
    };
  }

  reconnect(): void {
    this.updateConfig();
    this.pusher.disconnect();
    this.pusher.connect();
  }

  get isSubscribedToOrders(): boolean {
    return this.pusher
      .allChannels()
      .map((channel) => channel.name)
      .includes(ORDERS_CHANNEL);
  }

  subscribeToOrders(): void {
    this.channel = this.pusher.subscribe(ORDERS_CHANNEL);
    this.bindPusherSubscriptionSuccessHandler();
    this.bindPusherSubscriptionErrorHandler();
    this.bindPusherNewOrderHandler();
    this.bindPusherOrderStatusHandler();
  }

  bindPusherSubscriptionSuccessHandler(): void {
    if (!this.channel) return;
    this.channel.bind(PUSHER_SUCCESS, async () => {
      await Promise.all([
        this.store.orders.fetchOrders(OrdersTypeCode.NEW),
        this.store.orders.fetchOrders(OrdersTypeCode.IN_PREPARATION),
      ]);
    });
  }

  bindPusherSubscriptionErrorHandler(): void {
    if (!this.channel) return;
    this.channel.bind(PUSHER_ERROR, async () => {
      if (!this.isSubscribedToOrders) {
        toast.error(SUBSCRIPTION_ERROR, {autoClose: false});
        await this.store.session.refreshToken();
        Promise.all([
          this.store.orders.fetchOrders(OrdersTypeCode.NEW),
          this.store.orders.fetchOrders(OrdersTypeCode.IN_PREPARATION),
        ]);
      } else {
        await this.store.session.refreshToken();
        this.reconnect();
      }
    });
  }

  bindPusherNewOrderHandler(): void {
    if (!this.channel) return;
    this.channel.bind(
      PUSHER_NEW_ORDER,
      async ({payload: {restaurant: restaurantId}}: NewOrderData) => {
        if (String(restaurantId) !== this.store.orders.currentRestaurant)
          return;
        bell.play().catch((error) => {
          if (error.name === 'NotAllowedError') {
            this.isSoundDisabled = true;
          } else toast.error(error);
        });
        this.store.orders.fetchOrders(OrdersTypeCode.NEW);
      },
    );
  }

  bindPusherOrderStatusHandler(): void {
    if (!this.channel) return;
    this.channel.bind(
      PUSHER_ORDER_STATUS_CHANGE,
      ({
        payload: {id: orderId, oldStatus, newStatus, itemId},
      }: StatusChangedData) => {
        this.store.orders.handleAutomaticTransfer(
          orderId,
          oldStatus,
          newStatus,
          itemId,
        );
      },
    );
  }

  bindConfigChange(): void {
    if (!this.channel) return;
    this.channel.bind(CONFIGURATION_CHANGE, () => {
      this.store.orders.getBoxConfig();
    });
  }

  unsubscribeFromOrders(): void {
    if (this.channel) {
      this.channel.unbind_all();
      this.channel = null;
    }
    this.pusher.unsubscribe(ORDERS_CHANNEL);
  }

  setIsSoundDisabled(value: boolean): void {
    this.isSoundDisabled = value;
  }
}
