export const PUSHER_SUCCESS = 'pusher:subscription_succeeded';
export const PUSHER_ERROR = 'pusher:subscription_error';
export const PUSHER_NEW_ORDER = 'new_order_added';
export const PUSHER_ORDER_STATUS_CHANGE = 'order_status_changed';
export const CONFIGURATION_CHANGE = 'config_changed';

export const ORDERS_CHANNEL = 'private-orders';

export const SUBSCRIPTION_ERROR =
  'Wystąpił problem z powiadomieniami o nowych daniach - nowe dania pojawią się tylko po manualnym odświeżeniu strony.\nSpróbuj odświeżyć lub zalogować się ponownie.';
