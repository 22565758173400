import {toast} from 'react-toastify';
import {makeAutoObservable, reaction, runInAction} from 'mobx';

import api from 'api';
import {Order, OrderItem} from 'api/types';
import {OrdersTypeCode, TOKEN_INVALID_MESSAGE, TRAY_TIMEOUT} from 'consts';
import RootStore from 'stores/Root';

import {isString} from 'utils';

import {DragDish, TransferTarget, PendingAction} from './types';

import {
  FETCH_ORDERS_INVALID_TOKEN_ERROR,
  FETCH_ORDERS_RESPONSE_ERROR,
  FETCH_ORDER_FAIL_MISSING_RESTAURANT_ID,
  GET_BOX_CONFIG_FAIL,
  GET_BOX_CONFIG_FAIL_MISSING_RESTAURANT_ID,
  GET_RESTAURANT_FAIL,
  PREPARE_DISH_FAIL,
  RESTAURANT_ID,
} from './consts';

export default class OrdersStore {
  store: RootStore;

  isLoading: boolean;

  isDragging: boolean;

  pendingAction: PendingAction | undefined;

  timeoutRef: number | undefined;

  boxesEnabled: boolean;

  cancelActionTimeout: number;

  error: string | null;

  newOrders: Order[];

  inPreparation: Order[];

  readyToPickup: Order[];

  dragDish: DragDish | null;

  availableRestaurants: Record<string, string>;

  currentRestaurant: string | null;

  constructor(rootStore: RootStore) {
    this.store = rootStore;
    this.isLoading = false;
    this.isDragging = false;
    this.pendingAction = undefined;
    this.timeoutRef = undefined;
    this.boxesEnabled = false;
    this.cancelActionTimeout = 0;
    this.error = null;
    this.dragDish = null;
    this.newOrders = [];
    this.inPreparation = [];
    this.readyToPickup = [];
    this.availableRestaurants = {};
    this.currentRestaurant = localStorage.getItem(RESTAURANT_ID);

    makeAutoObservable(this, {}, {autoBind: true});
    reaction(() => this.readyToPickup, this.scheduleOrderRemoval);
  }

  setLoading(value: boolean): void {
    this.isLoading = value;
  }

  reset(): void {
    this.store.pusher.unsubscribeFromOrders();
    this.isLoading = false;
    this.isDragging = false;
    this.pendingAction = undefined;
    this.timeoutRef = undefined;
    this.boxesEnabled = false;
    this.error = null;
    this.dragDish = null;
    this.newOrders = [];
    this.inPreparation = [];
    this.readyToPickup = [];
  }

  clearError(): void {
    this.error = null;
  }

  setDragDish(dish: DragDish): void {
    this.dragDish = dish;
  }

  setPendingAction(action: () => void): void {
    this.pendingAction = () =>
      new Promise((resolve) => {
        action();
        resolve();
      });
  }

  setTimeoutRef(ref: number): void {
    this.timeoutRef = ref;
  }

  removePendingAction(): void {
    if (this.timeoutRef) {
      this.pendingAction = undefined;
      clearTimeout(this.timeoutRef);
    }
  }

  clearDragDish(): void {
    this.dragDish = null;
  }

  async changeRestaurant(selectedRestaurantId: string): Promise<void> {
    if (selectedRestaurantId !== this.currentRestaurant) {
      this.currentRestaurant = selectedRestaurantId;
      this.getBoxConfig();
      localStorage.setItem(RESTAURANT_ID, selectedRestaurantId);
      await this.fetchOrders(OrdersTypeCode.NEW);
      await this.fetchOrders(OrdersTypeCode.IN_PREPARATION);
    }
  }

  async getUserRestaurants(): Promise<void> {
    try {
      this.setLoading(true);
      const {data} = await api.getUserRestaurants();
      runInAction(() => {
        if (data?.userRestaurants?.length) {
          this.availableRestaurants = {};
          let isCachedRestaurantIdValid = false;
          data.userRestaurants.forEach(({id, name}) => {
            this.availableRestaurants[id] = name;
            if (id === this.currentRestaurant) {
              isCachedRestaurantIdValid = true;
            }
          });
          if (!isCachedRestaurantIdValid) {
            this.changeRestaurant(data.userRestaurants[0].id);
          }
        }
      });
    } catch (error) {
      toast.error(GET_RESTAURANT_FAIL);
    } finally {
      this.setLoading(false);
    }
  }

  async getBoxConfig(): Promise<void> {
    try {
      isString(
        this.currentRestaurant,
        GET_BOX_CONFIG_FAIL_MISSING_RESTAURANT_ID,
      );
      this.setLoading(true);
      const {data} = await api.getBoxConfig({
        restaurantId: Number(this.currentRestaurant),
      });
      runInAction(() => {
        this.boxesEnabled = !!data?.boxConfig.enabled;
        if (data) {
          this.cancelActionTimeout = data.boxConfig.cancelActionTime;
        }
      });
    } catch (error) {
      toast.error(GET_BOX_CONFIG_FAIL);
    } finally {
      this.setLoading(false);
    }
  }

  async fetchOrders(typeCode: OrdersTypeCode): Promise<void> {
    try {
      isString(this.currentRestaurant, FETCH_ORDER_FAIL_MISSING_RESTAURANT_ID);
      this.setLoading(true);
      const {data, errors} = await api.fetchOrders({
        status: typeCode,
        restaurantId: Number(this.currentRestaurant),
      });
      if (errors) {
        toast.error(FETCH_ORDERS_RESPONSE_ERROR, {autoClose: false});
      }
      if (data?.basketItemList) {
        runInAction(() => {
          const orders = data.basketItemList.itemGroups.slice().reverse();
          if (typeCode === OrdersTypeCode.NEW) {
            this.newOrders = orders;
          } else {
            this.inPreparation = orders;
          }
        });
      }
    } catch (error) {
      if (error.networkError?.error?.message === TOKEN_INVALID_MESSAGE) {
        toast.error(FETCH_ORDERS_INVALID_TOKEN_ERROR, {
          onClose: this.store.session.logOut,
        });
      } else {
        runInAction(() => {
          this.error = String(error);
        });
      }
    } finally {
      this.setLoading(false);
    }
  }

  startPrepareDish(): void {
    const handler = async (dishId: string): Promise<boolean> => {
      const {data} = await api.startPrepareDish(dishId);
      return !!data?.result.success;
    };
    this.processDish(
      handler,
      TransferTarget.newOrders,
      TransferTarget.inPreparation,
    );
  }

  completePrepareDish(): void {
    const handler = async (dishId: string): Promise<boolean> => {
      const {data} = await api.completePrepareDish(dishId);
      return !!data?.result.success;
    };
    this.processDish(
      handler,
      TransferTarget.inPreparation,
      TransferTarget.readyToPickup,
    );
  }

  private async processDish(
    handler: (dishId: string) => Promise<boolean>,
    transferSource: TransferTarget,
    transferTarget: TransferTarget,
  ): Promise<void> {
    if (!this.dragDish) return;
    try {
      this.setLoading(true);
      if (await handler(this.dragDish.id)) {
        this.transferDragDish(transferSource, transferTarget);
      } else throw new Error(PREPARE_DISH_FAIL);
    } catch (error) {
      runInAction(() => {
        this.error = String(error);
      });
    } finally {
      runInAction(() => {
        this.dragDish = null;
      });
      this.setLoading(false);
    }
  }

  transferDragDish(
    source: TransferTarget,
    target: TransferTarget,
    reverse?: boolean,
  ): void {
    if (!this.dragDish) return;
    this.transferDish(this.dragDish, source, target, reverse);
  }

  transferDish(
    dishInfo: DragDish,
    source: TransferTarget,
    target: TransferTarget,
    reverse?: boolean,
  ): void {
    const {id: dishId, orderId, isSingle} = dishInfo;
    const sourceOrder = this[source].find(({id}) => id === orderId);
    const targetOrder = this[target].find(({id}) => id === orderId);
    if (sourceOrder) {
      const dish = OrdersStore.removeOrderItem(sourceOrder, dishId);
      // notify observers of change
      this[source] = [...this[source]];
      if (dish) {
        this.addDishToOrder(target, dish, sourceOrder, targetOrder);
        if (isSingle && !reverse) this.transferOrder(orderId, source, target);

        if (reverse) {
          if (sourceOrder.items.length === 0) {
            this.transferOrder(orderId, source, target);
          }
        }
      }
    }
  }

  handleAutomaticTransfer(
    orderId: string,
    oldStatus: OrdersTypeCode,
    newStatus: OrdersTypeCode,
    dishId?: string,
  ): void {
    if (
      Number(oldStatus) === OrdersTypeCode.PREPARED &&
      Number(newStatus) === OrdersTypeCode.DELIVERED
    ) {
      this.store.orders.removeOrderFromTray(orderId);
    } else {
      try {
        if (dishId) {
          const isSingle = this.isOrderSingle(oldStatus, orderId);
          this.transferDish(
            {orderId, id: dishId, isSingle},
            OrdersStore.mapStatusToTarget(oldStatus),
            OrdersStore.mapStatusToTarget(newStatus),
          );
        } else {
          this.transferOrder(
            orderId,
            OrdersStore.mapStatusToTarget(oldStatus),
            OrdersStore.mapStatusToTarget(newStatus),
          );
        }
      } catch (error) {
        toast.error(`Failed to auto-transfer Order: ${error?.message}`);
      }
    }
  }

  transferOrder(
    orderId: string,
    source: TransferTarget,
    target: TransferTarget,
  ): void {
    const sourceIndex = this[source].findIndex(({id}) => id === orderId);
    const targetIndex = this[target].findIndex(({id}) => id === orderId);
    if (sourceIndex === -1) return;
    const order = this[source][sourceIndex];
    this[source] = [
      ...this[source].slice(0, sourceIndex),
      ...this[source].slice(sourceIndex + 1),
    ];
    if (targetIndex === -1) {
      this[target] = [order, ...this[target]];
    }
  }

  removeOrderFromTray(orderId: string): void {
    const trayIndex = this.readyToPickup.findIndex(({id}) => id === orderId);
    if (trayIndex === -1) return;
    this.readyToPickup = [
      ...this.readyToPickup.slice(0, trayIndex),
      ...this.readyToPickup.slice(trayIndex + 1),
    ];
  }

  addDishToOrder(
    target: TransferTarget,
    dish: OrderItem,
    sourceOrder: Order,
    targetOrder: Order | undefined,
  ): void {
    if (targetOrder) {
      OrdersStore.addOrderItem(targetOrder, dish);
      // notify observers of change
      this[target] = [...this[target]];
    } else {
      this[target] = [...this[target], {...sourceOrder, items: [dish]}];
    }
  }

  scheduleOrderRemoval(): void {
    if (this.boxesEnabled) return;

    setTimeout(() => {
      runInAction(() => {
        // array remains the same object
        this.readyToPickup.pop();
      });
    }, TRAY_TIMEOUT);
  }

  isOrderSingle(statusNumber: OrdersTypeCode, orderId?: string): boolean {
    if (!orderId) return false;
    const target = OrdersStore.mapStatusToTarget(statusNumber);
    return this[target].find(({id}) => id === orderId)?.items.length === 1;
  }

  isOrderComplete(orderId: string): boolean {
    return !this.newOrders.find((order: Order) => order.id === orderId);
  }

  static addOrderItem(order: Order, item: OrderItem): void {
    order.items.push(item);
  }

  static removeOrderItem(order: Order, itemId: string): OrderItem | undefined {
    const itemIndex = order.items.findIndex((dish) => dish.id === itemId);
    return itemIndex !== -1 ? order.items.splice(itemIndex, 1)[0] : undefined;
  }

  static mapStatusToTarget(status: OrdersTypeCode): TransferTarget {
    switch (status) {
      case OrdersTypeCode.NEW:
        return TransferTarget.newOrders;
      case OrdersTypeCode.IN_PREPARATION:
        return TransferTarget.inPreparation;
      case OrdersTypeCode.PREPARED:
        return TransferTarget.readyToPickup;
      default:
        throw new Error(`Invalid OrderStatus code detected: ${status}`);
    }
  }
}
