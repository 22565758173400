import {CountWrapper, Value} from './styles';
import {DishCountProps} from './types';

const DishCount: React.FC<DishCountProps> = ({
  children,
  inKitchen,
  ...props
}) => (
  <CountWrapper {...props}>
    <Value inKitchen={inKitchen}>{children}</Value>
    szt
  </CountWrapper>
);

export default DishCount;
