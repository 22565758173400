import {styled} from 'styles';

export const AlertWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Alert = styled.div`
  ${({theme}) => `
    position: relative;
    box-shadow: ${theme.shadows[3]};
    background: ${theme.palette.background.main};
    width: calc(100% - ${theme.spacing.base * 6}px);
    max-width: 420px;
    margin-left: ${theme.spacing.base * 3}px;
    margin-right: ${theme.spacing.base * 3}px;
    padding: ${theme.spacing.base * 5}px;
  `}
`;

export const AlertText = styled.p`
  ${({theme}) => `
    text-align: center;
    margin-bottom: ${theme.spacing.base * 5}px;
    font-size: ${theme.font.size.md};
    font-family: ${theme.font.family.fancy};
    font-weight: 900;
    color: ${theme.palette.primary.main};
  `}
`;
