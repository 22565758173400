import {ToastContainer} from 'react-toastify';
import {observer} from 'mobx-react-lite';

import {TOAST_AUTOCLOSE} from 'consts';
import Container from 'components/Container';
import Login from 'containers/Login';
import Orders from 'containers/Orders';
import {useStore} from 'utils';

const App: React.FC = () => {
  const {sessionStore} = useStore();

  return (
    <Container>
      {sessionStore.loggedIn ? <Orders /> : <Login />}
      <ToastContainer position="bottom-center" autoClose={TOAST_AUTOCLOSE} />
    </Container>
  );
};

export default observer(App);
